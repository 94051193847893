/* ! themes/_deep-blue.scss | Bulkit | CSS Ninja */

/* ==========================================================================
(meinCartoon) Deep Blue theme variables and styles (Deep blue theme is used in "Landing kit v3")
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Theme color variables
1. Box shadows
2. Theme gradient
3. Pageloader
4. Theme specific Hero
=============================================================================
***/

/* ==========================================================================
0. Theme color variables
========================================================================== */
$primary: #0A4BA3;
$primary-accent: #1177fc;
$secondary: #1aad3e;
$accent: #1dc146;


/* ==========================================================================
1. Box shadows
========================================================================== */
//Base shadow
$base-shadow: rgba(0, 0, 0, 0.12);

//Primary box shadow
$primary-shadow-from: rgba(57, 106, 252, 0.42);
$primary-shadow-to: rgba(57, 106, 252, 0.2);
$primary-box-shadow:  0 14px 26px -12px $primary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $primary-shadow-to !important;

//Secondary box shadow
$secondary-shadow-from: rgba(124, 77, 255, 0.42);
$secondary-shadow-to: rgba(124, 77, 255, 0.2);
$secondary-box-shadow:  0 14px 26px -12px $secondary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $secondary-shadow-to !important;

//Accent box shadow
$accent-shadow-from: rgba(255, 114, 115, 0.42);
$accent-shadow-to: rgba(255, 114, 115, 0.2);
$accent-box-shadow:  0 14px 26px -12px $accent-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $accent-shadow-to !important;


/* ==========================================================================
2. Theme gradient
========================================================================== */
$webkit-hero-gradient: -webkit-linear-gradient(45deg, $primary, $primary-accent);
$hero-gradient: linear-gradient(45deg, $primary, $primary-accent); 

$webkit-section-gradient: -webkit-linear-gradient(45deg, $primary, $secondary); 
$section-gradient: linear-gradient(45deg, $primary, $secondary); 


/* ==========================================================================
3. Pageloader
========================================================================== */
.pageloader {
    background-color: $primary;
    background: $webkit-hero-gradient;
    background: $hero-gradient;
}

/* ==========================================================================
4. Theme specific Hero
========================================================================== */
.hero, .section {
    &.is-theme-primary {
        background: $webkit-hero-gradient;
        background: $hero-gradient;
        .title {
            color: $white;
        }
        .subtitle {
            color: $white;
        }
    }
}

.hero, .section {
    &.is-theme-secondary {
        background: $webkit-section-gradient;
        background: $section-gradient;
        .title {
            color: $white;
        }
        .subtitle {
            color: $white;
        }
    }
}

// Tobi
.fileuploader-input{
    box-shadow: 0 14px 26px -12px rgba(0,0,0,0.42),0 4px 23px 0px rgba(0,0,0,0.12),0 8px 10px -5px rgba(0,0,0,0.2) !important;
}

#large-header{
    height: auto !important;
}

@media (max-width: 768px){
    #main-hero {
        max-height: inherit !important;
    }
}

.phone-slide.is-third {
    background-image: url(/images/illustrations/mockups/startup/phone-slide-3.png);
}
.phone-slide.is-fourth {
    background-image: url(/images/illustrations/mockups/startup/phone-slide-4.png);
}
.phone-slide {
    background-position: center left;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 50%;
    vertical-align: top;
    padding: 800px 30px 30px 30px;
}


/* ==========================================================================
8. Contact Form
========================================================================== */

//Form
.contact-form {
    width: 100%;
    padding: 40px 20px;
    label {
        font-weight: 600;
        color: $blue-grey;
    }
    .submit-wrapper {
        //padding: 5px 0;
        text-align: right;
    }
}

//Contact Tabs
.contact-toggler {
    padding: 40px 0;
    .tabbed-links {
        li {
            display: inline-block;
            padding: 10px;
            color: $placeholder;
            border-top: 2px solid transparent;
            cursor: pointer;
            &.is-active {
                color: $blue-grey;
                font-weight: 500;
                border-top: 2px solid $secondary;
            }
        }
    }
    //Wrapper
    .contact-blocks {
        //Tab content
        .contact-block {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 20px 0;
            .contact-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                background: $primary;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                i {
                    font-size: 1.3rem;
                    color: $white;
                }
            }
            .contact-info {
                padding: 0 10px;
                span {
                    display: block;
                    &:first-child {
                        font-weight: 500;
                        color: $blue-grey;
                    }
                    &:nth-child(2) {
                        font-weight: 500;
                        color: $blue;
                    }
                }
            }
        }
    }
}


.slick-slider .slick-slide > .image-wrapper {
    opacity: 0.8;
    transition: all 300ms ease;
    -moz-transform: scale(0.92);
    -ms-transform: scale(0.92);
    -o-transform: scale(0.92);
    -webkit-transform: scale(0.92);
    transform: scale(0.92);
}
.slick-slider .slick-slide.slick-center > .image-wrapper {
    opacity: 1;
    -moz-transform: scale(1.0);
    -ms-transform: scale(1.0);
    -o-transform: scale(1.0);
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
}

.navbar .button-signup{
    border-color: $accent !important;
    color: $accent !important;
}
.navbar.is-transparent .button-signup{
    display: none;
}

.fileuploader-input-button{
    background: $accent !important;
}

.vertical-testimonials {
    margin-top: 10px !important;
}

//gap bug
.hero.hero-wavy:before {
    bottom: -2px !important;
}